import router from './router'
import { vantToast, wxSDK } from './utils'
import store from './store'
import { smsWechatAuthUrlAPI, smsWechatJssdkConfigAPI } from './api/auth'

const whiteList = ['/', '/auth']
const publicNameList = ['service_recommend', 'service_recommend_content']

router.beforeEach(async(to, from, next) => {
  if (publicNameList.includes(to.name)) return next()
  document.title = '神速软件-' + to.meta.title
  const toast = vantToast()
  if (!whiteList.includes(to.path) && !store.getters.token) {
    const { code, data } = await smsWechatAuthUrlAPI()
    if (code !== 200) return next()
    location.href = data
    toast.close()
    return next()
  }
  const { code, data } = await smsWechatJssdkConfigAPI(location.href)
  if (code !== 200) return next()
  const obj = Object.assign({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: '', // 必填，公众号的唯一标识
    timestamp: '', // 必填，生成签名的时间戳
    nonceStr: '', // 必填，生成签名的随机串
    signature: '', // 必填，签名
    jsApiList: ['scanQRCode', 'closeWindow', 'getNetworkType', 'invoke'] // 必填，需要使用的JS接口列表
  }, data)
  delete obj.url
  wxSDK.config(obj)
  toast.close()
  next()
})

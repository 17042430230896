import store from '@/store'
import axios from 'axios'
import { closeToast, showDialog, showFailToast } from 'vant'
import { wxSDK } from '.'
import { smsWechatAuthUrlAPI } from '@/api/auth'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API + '/api/v1',
  timeout: 30 * 1000 // 超时时间
})

// 添加请求拦截器
request.interceptors.request.use(function(config) {
  // 在发送请求之前做些什么
  const token = store.getters.token
  // console.log(token)
  if (store.getters.token) {
    config.headers.Authorization = token
  }
  return config
}, function(error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(async function(response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  const res = response.data
  const { code, msg, data } = res
  if (!code) return Promise.reject(msg)
  if (code === 200) return res
  if (code === 10104) {
    showFailToast(msg)
    const { code, data } = await smsWechatAuthUrlAPI()
    if (code !== 200) return Promise.reject(msg)
    location.href = data
    return res
  }
  if (code === 10105) {
    showFailToast(msg)
    location.href = data.auth_url
    return res
  }
  if (code !== 200) {
    closeToast()
    showDialog({
      message: msg,
      theme: 'round-button',
      confirmButtonText: '知道了'
    }).then(() => {
      wxSDK.ready(function() {
        if (code === 10106) {
          wxSDK.scanQRCode({
            needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
            success: function(res) {
              // const result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
            }
          })
        }
        // wxSDK.closeWindow()
      })
    })
  }
  return res
}, function(error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default request

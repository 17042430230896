import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home'),
    meta: { title: '首页' }
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/Auth'),
    meta: { title: '授权' }
  },
  {
    path: '/auth_success',
    name: 'auth_success',
    component: () => import('@/views/AuthSuccess'),
    meta: { title: '授权' }
  },
  {
    path: '/binding_shop_list',
    name: 'binding_shop_list',
    component: () => import('@/views/ShopBindingList'),
    meta: { title: '绑定店铺' }
  },
  {
    path: '/help_desc',
    name: 'help_desc',
    component: () => import('@/views/ShopBindingList/components/HelpDesc'),
    meta: { title: '帮助说明' }
  },
  {
    path: '/profit_statistic',
    name: 'profit_statistic',
    component: () => import('@/views/ProfitStatistic'),
    meta: { title: '利润查询' }
  },
  {
    path: '/order_statistic',
    name: 'order_statistic',
    component: () => import('@/views/OrderStatistic'),
    meta: { title: '订单查询' }
  },
  {
    path: '/service_recommend',
    name: 'service_recommend',
    component: () => import('@/views/ServiceRecommend'),
    meta: { title: '服务推荐' }
  },
  {
    path: '/service_recommend_content/:type',
    name: 'service_recommend_content',
    component: () => import('@/views/ServiceRecommendChild'),
    meta: { title: '服务推荐' }
  },
  {
    path: '/vip_welfare',
    name: 'vip_welfare',
    component: () => import('@/views/VipWelfare'),
    meta: { title: '会员福利' }
  },
  {
    path: '/order_detail',
    name: 'order_detail',
    component: () => import('@/views/OrderDetail'),
    meta: { title: '我的订单' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

import { showLoadingToast } from 'vant'

/**
 * 延迟器
 */
export function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time))
}

/**
 * 微信sdk对象
 */
// eslint-disable-next-line no-undef
export const wxSDK = wx

/**
 * 页面跳转
 */
export function pageJump(obj) {
  if (typeof obj === 'string') {
    location.href = obj
    return
  }
  const { path, query } = obj
  location.href = path + objToUrlCode(query)
}

/**
 * js对象 - urlcode
 */
export function objToUrlCode(obj) {
  var arr = []
  for (var k in obj) {
    arr.push(k + '=' + obj[k])
  }
  if (!arr.length) return ''
  return '?' + arr.join('&')
}

/**
 * 获取除origin后的参数
 */
export function getUrl() {
  return location.href.split(location.origin)[1]
}

/**
 * vant-toast-loading
 */
export function vantToast(message = '加载中...', duration = 0) {
  const toast = showLoadingToast({
    message,
    duration,
    forbidClick: true
  })
  return {
    close: function() {
      toast.close()
    }
  }
}

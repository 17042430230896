import { getToken, removeToken, setToken } from '@/utils/auth'

const state = {
  token: getToken()
}
const mutations = {
  setTokenFn (state, { token, expires }) {
    state.token = token
    setToken(token, new Date(Date.now() + expires * 1000))
  },
  removeTokenFn (state) {
    state.token = null
    removeToken()
  }
}
const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}

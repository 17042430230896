import { getUrl } from '@/utils'
import request from '@/utils/request'

/**
 * 授权code获取token
 */
export const smsWechatAuthCodeAPI = data => request({
  url: '/sms/wechat/auth/code',
  method: 'POST',
  data
})

/**
 * 获取微信授权url
 */
export const smsWechatAuthUrlAPI = (origin_url = getUrl()) => request({
  url: '/sms/wechat/auth/url',
  method: 'POST',
  data: {
    origin_url
  }
})

/**
 * 前端获取公众号js-sdk配置参数
 */
export const smsWechatJssdkConfigAPI = url => request({
  url: '/sms/wechat/jssdk/config',
  method: 'POST',
  data: {
    url
  }
})

/**
 * 订单统计
 */
export const smsWechatOrderStatisticAPI = shop_id => request({
  url: '/sms/wechat/order/statistic',
  method: 'GET',
  params: {
    shop_id
  }
})

/**
 * 利润统计
 */
export const smsWechatProfitStatisticAPI = shop_id => request({
  url: '/sms/wechat/profit/statistic',
  method: 'GET',
  params: {
    shop_id
  }
})

/**
 * 店铺列表
 */
export const smsWechatStoreListAPI = type => request({
  url: '/sms/wechat/store/list',
  method: 'GET',
  params: {
    type
  }
})

/**
 * 店铺解绑
 */
export const smsWechatUnbindingAPI = data => request({
  url: '/sms/wechat/unbinding',
  method: 'PUT',
  data
})
